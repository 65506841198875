<template>
  <div class="rolldetailbox">
    <div class="center" :class="{'mb_center':isMinDevice}">
      <div class="commercial">
        <img class="w100" :src="onlineUrl + finalFuncImgs[8]" alt="" />
      </div>
      <div class="title" :class="{'mb_title':isMinDevice}">
        <div class="top" :class="{'mb_top':isMinDevice}">
          {{datalist.name}}
        </div>
        <div class="main" :class="{'mb_main':isMinDevice}">
          {{datalist.describe}}
        </div>
        <div class="footer" :class="{'mb_footer':isMinDevice}">
          <p class="btn " style="background:#7D7E80;cursor: not-allowed" :class="{'mb_btn':isMinDevice}" v-if="datalist.status==1">Join</p>
          <p class="btn point" :class="{'mb_btn':isMinDevice}" v-if="datalist.status==0" @click="clickjoin">Join</p>
        </div>
      </div>
      <div class="rolllist">
        <div class="name" :class="{'mb_name':isMinDevice}">Pond</div>
        <div class="datalist">
          <div style="
              width: 14.4rem;
              margin: 0.3rem auto;
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
            " :style="isMinDevice?'width:16.12rem':''">
            <div class="item" :class="{'mb_item':isMinDevice,'mb_he':isMinDevice&&datalist.status==1}" :style="datalist.status==1 &&!isMinDevice? 'height:3.2rem' :''" v-for="item , index in userlist" :key="index">
              <div class="imgbox" :class="{'mb_imgbox':isMinDevice}">
                <img :src="onlineUrl+item?.image" alt="" />
              </div>
              <div class="numsbox" :class="{'mb_numsbox':isMinDevice}">X {{item?.nums}}</div>
              <div class="infobox" :class="{'mb_infobox':isMinDevice}">
                {{item?.title}}
              </div>
              <div class="price" :class="{'mb_price':isMinDevice}">$ {{item?.price}} <div class="flex-c" style="margin: 0.1rem 0 0 0 ;" v-if="datalist.status==1 &&item?.get_user.avatar!=undefined"><img class="gethead"
                    :src="onlineUrl+item?.get_user.avatar" :class="{'mb_gethead':isMinDevice}" alt="">
                  <span style="font-size:0.18rem；white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :style="isMinDevice? 'font-size:0.5rem':'' ">{{item?.get_user.nickname}}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 0.25rem" v-if="datalist.status!=1">
          <div class="name2" :class="{'mb_name2':isMinDevice}">Participating user</div>
          <span class="people" :class="{'mb_people':isMinDevice}">{{datalist.users?.length}} people</span>
        </div>
        <div class="headbox" :class="{'mb_headerbox':isMinDevice}" v-if="datalist.status!=1">
          <div class="headimg" :class="{'mb_headimg':isMinDevice}" v-for="item in datalist.users" :key="item.id">
            <img :src="onlineUrl + item.avatar" alt="" />
          </div>

        </div>
      </div>
    </div>
    <div class="changeview" v-if="joinroll">
      <div class="namebox" :class="{'mb_namebox':isMinDevice}" v-show="joinroll">
        <div class="title" :class="{'mb_nametitle':isMinDevice}">
          Password
          <img class="point" src="../../assets/images/pc/pc-home-icon10.png" alt="" @click="() => {
            (this.joinroll = false);
          }
            " />
        </div>
        <div class="flex-c" :class="{'mb_putbox':isMinDevice}">
          <input class="putbox" type="text" placeholder="Please enter the room code" v-model="password" />
        </div>
        <div class="flex-c">
          <div class="amend2 point" :class="{'mb_amen':isMinDevice}" @click="entepass">Join</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getRolldetailApi, JoinRollApi } from '../../network/api';
export default {
  data() {
    return {
      datalist: [],
      joinroll: false,// 密码弹窗
      password: '',//输入密码
      id: '',
      userlist: []//中奖用户展示
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getdetaildata(this.id);

  },
  methods: {
    async entepass() {
      let params = {
        id: this.id,
        password: this.password
      };
      const res = await JoinRollApi(params);
      if (res.code == 1) {
        this.$message.success(res.msg);
        this.joinroll = false;
        this.getdetaildata(this.id);
      }
    },
    async clickjoin() {
      if (this.datalist.is_pwd == true) {
        this.password=''
        this.joinroll = true;
      } else {
        let params = {
          id: this.id,
        };
        const res = await JoinRollApi(params);
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.getdetaildata(this.id);
        }
      }

    },
    async getdetaildata(id) {
      const res = await getRolldetailApi(id);
      this.datalist = res.data;
      if (this.datalist.status == 0) {
        this.userlist = this.datalist.awards;
      } else if (this.datalist.status == 1) {
        this.userlist = this.datalist.awards.filter((v) => {
          return v.get_user != '';
        });
      }

    }
  },
  computed: {
    ...mapGetters(["finalFuncImgs"]),
    ...mapState(['isMinDevice', "onlineUrl"]),

  },
};
</script>

<style lang="scss" scoped>
.rolldetailbox {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .center {
    width: 14.74rem;
    margin: 0 auto;

    .commercial {
      width: 100%;
      //   height: 0.8rem;
      //   background-color: #dfdfdf;
      margin-top: 0.66rem;
    }

    .title {
      width: 100%;
      height: 2.43rem;
      border-radius: 0.06rem;
      border: 0.01rem solid #cfcfcf;
      margin-top: 0.75rem;
      box-shadow: 0 0.04rem 0.04rem 0 rgba($color: #000000, $alpha: 0.25);

      .top {
        width: 100%;
        height: 0.8rem;
        display: flex;
        align-items: center;
        padding-left: 0.18rem;
        font-size: 0.28rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 0.33rem;
      }

      .main {
        width: 14.38rem;
        height: 0.7rem;
        margin: 0 auto;
        font-size: 0.24rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 0.28rem;
      }

      .footer {
        display: flex;
        justify-content: end;
        padding-right: 0.18rem;

        .btn {
          width: 1.9rem;
          height: 0.45rem;
          background: #0076ff;
          border-radius: 0.06rem;
          text-align: center;
          line-height: 0.45rem;
          font-size: 0.18rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
    .mb_title {
      border-radius: 0.12rem;
      height: unset;
      .mb_top {
        line-height: 0.8rem;
        font-size: 0.6rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0.2rem;
      }
      .mb_main {
        width: 100%;
        font-size: 0.5rem;
        line-height: 0.7rem;
        height: 3rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #000000;
        padding-left: 0.1rem;
      }
      .mb_footer {
        padding-right: 0.87rem;
        .mb_btn {
          width: 5rem;
          height: 0.89rem;
          border-radius: 0.12rem;
          line-height: 0.89rem;
          font-size: 0.6rem;
        }
      }
    }

    .rolllist {
      margin-top: 0.3rem;

      .name {
        width: 0.8rem;
        height: 0.3rem;
        border-right: 0.02rem solid;
        line-height: 0.3rem;
        text-align: left;
        font-size: 0.22rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0.47rem;
      }
      .mb_name {
        width: 2.5rem;
        height: 1.024rem;
        border-right: 0.1rem solid;
        font-size: 0.7rem;
        line-height: 1.024rem;
        font-weight: 600;
      }

      .name2 {
        width: 2.07rem;
        height: 0.3rem;
        border-right: 0.02rem solid;
        line-height: 0.3rem;
        text-align: left;
        font-size: 0.22rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .people {
        width: 1.8rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        font-size: 22px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .mb_name2 {
        width: 6.7rem;
        height: 1.024rem;
        line-height: 1.024rem;
        border-right: 0.1rem solid;
        font-size: 0.7rem;
        line-height: 1.024rem;
        font-weight: 600;
      }
      .mb_people {
        width: 3.6rem;
        font-size: 0.7rem;
        height: 1.024rem;
        line-height: 1.024rem;
        font-weight: 600;
      }

      .datalist {
        width: 100%;
        background: #f8f8f8;
        overflow-y: scroll;
        margin-bottom: 0.75rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .item {
          width: 2.2rem;
          height: 2.8rem;
          background: url("../../assets/images/pc/pc-roll-detailecard.png")
            no-repeat;
          background-size: 100% 100%;
          margin: 0.2rem 0.05rem 0 0.1rem;
          position: relative;
          .imgbox {
            width: 1.8rem;
            height: 2.04rem;
            margin: 0.1rem auto;

            img {
              display: block;
              height: 100%;
              margin: 0 auto;
            }
          }
          .numsbox {
            width: 0.5rem;
            height: 0.3rem;
            border-radius: 0 0.06rem 0 0.06rem;
            position: absolute;
            top: 0;
            right: 0;
            background: #003879;
            text-align: center;
            color: #ffffff;
            line-height: 0.3rem;
          }

          .infobox {
            width: 2rem;
            height: 0.21rem;
            margin: 0 auto;
            font-size: 0.18rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 0.21rem;
            margin-top: 0.1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .price {
            width: 100%;
            height: 0.2rem;
            text-align: center;
            line-height: 0.2rem;
            font-size: 0.24rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            margin-top: 0.1rem;
            .gethead {
              width: 0.25rem;
              height: 0.25rem;
              border-radius: 50%;
              margin-right: 0.2rem;
            }
          }
        }
        .mb_item {
          width: 7.68rem;
          height: 8.5rem;
          margin: 0.25rem 0.1rem 0 0.25rem;
          border-radius: 0.12rem;
          .mb_imgbox {
            width: 5.4rem;
            height: 6.19rem;
          }
          .mb_numsbox {
            width: 1.45rem;
            height: 0.8rem;
            border-radius: 0 0.12rem 0 0.12rem;
            line-height: 0.8rem;
            font-size: 0.5rem;
          }
          .mb_infobox {
            width: 6.3rem;
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.6rem;
            margin-top: 0.2rem;
          }
          .mb_price {
            height: 0.87rem;
            line-height: 0.87rem;
            font-size: 0.6rem;
            margin-top: 0.4rem;
            .mb_gethead {
              width: 0.8rem;
              height: 0.8rem;
              margin-right: 0.5rem;
            }
          }
        }
        .mb_he {
          height: 9.5rem;
        }
      }

      .headbox {
        width: 100%;
        height: 2.25rem;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;

        &::-webkit-scrollbar {
          display: none;
        }

        .headimg {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          margin-right: 0.3rem;
          margin-top: 0.1rem;

          img {
            display: block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
          }
        }
      }
      .mb_headerbox {
        height: 5.4rem;
        .mb_headimg {
          width: 2.6rem;
          height: 2.6rem;
          margin-right: 0.8rem;
          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .mb_center {
    width: 17.15rem;
  }
  .changeview {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.2);
    z-index: 1999;

    .title {
      width: 100%;
      height: 0.8rem;
      background: #002550;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.28rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      border-radius: 0.12rem 0.12rem 0 0;
      position: relative;

      img {
        display: block;
        width: 0.35rem;
        height: 0.35rem;
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
      }
    }

    .amend2 {
      width: 3.18rem;
      height: 0.6rem;
      border-radius: 0.04rem;
      background: #0076ff;
      font-size: 0.2rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.6rem;
      text-align: center;
    }
    .mb_amen {
      width: 4.7rem;
      height: 1.07rem;
      font-size: 0.7rem;
      line-height: 1.07rem;
      margin-bottom: 0.5rem;
    }

    .namebox {
      width: 8.5rem;
      height: 3.17rem;
      border-radius: 0.12rem;
      background: #fff;

      .putbox {
        width: 6.9rem;
        height: 0.64rem;
        margin: 0.2rem auto;
        border: 0.01rem solid #b7b7b7;
        text-decoration: none;
        text-align: center;
        font-size: 0.28rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #848484;
      }
      .mb_putbox {
        height: 2.9rem;
        background: #fff;
        input {
          width: 15rem;
          height: 1.2rem;
          font-size: 0.6rem;
        }
      }
    }
    .mb_namebox {
      width: 17rem;
      height: unset;
      .mb_nametitle {
        height: 1.5rem;
        font-size: 0.7rem;
        line-height: 1.5rem;
        img {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
  }
}
</style>